import styled from 'styled-components'
import media from '../utils/breakpoints'
export const ContactWrapper = styled.section`
  div.content {
    place-content: unset;
    align-content: center;
    form {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      label {
        display: block;
        width: 100%;
        padding: 1rem 0 0rem;
      }
      input,
      textarea {
        display: block;
        width: 100%;
        border: 1px solid #f0f0f1;
        color: var(--navy);
        padding: 0.2rem 1rem;
        border-radius: 5px;
        margin-top: 0.2rem;
        &::placeholder {
          color: #b1b1b1;
        }
      }
      textarea {
        resize: vertical;
      }
      button {
        display: block;
        cursor: pointer;
        width: 100%;
        margin: 2rem 0 0;
        border: none;
        color: var(--cool-white);
        background: var(--blue);
        padding: 0.3rem;
        border-radius: 5px;
        transition: background-color ease 350ms;
        &:hover {
          background-color: var(--navy2);
        }
      }
    }
  }
`

export const ContactMethodWrapper = styled.div`
  padding: 1.5rem 0;
  h1 {
    text-align: center;
  }

  div.methods {
    background: url("data:image/svg+xml,%3Csvg width='234' height='83' viewBox='0 0 234 83' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M95 44.0001C255 36.0001 250 81.0001 205 81.0001C160 81.0001 151 56 126 65C101 74 3.99999 62.0001 1.99999 44.0001C-7.09295e-06 26 36 32 55 1' stroke='%2376FBDD' stroke-width='3'/%3E%3C/svg%3E%0A")
        bottom left / 15rem no-repeat,
      url("data:image/svg+xml,%3Csvg width='1005' height='308' viewBox='0 0 1005 308' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M95 269C255 261 250 306 205 306C160 306 151 281 126 290C101 299 3.99999 287 1.99999 269C-7.09295e-06 251 36 257 55 226M989 80C1005.67 63.6667 1021.6 30.2 952 27C865 23 861 -5.99999 818 4.00001C775 14 801 36 827 41' stroke='%2376FBDD' stroke-width='3'/%3E%3C/svg%3E%0A")
        top right / 70rem no-repeat;

    padding: 2rem 0;
    display: grid;
    grid-template-columns: 1fr;
    cursor: pointer;
    div.method {
      background-color: #f8f7fc;
      margin: 1rem;
      border: 2px solid #cdc1ff;
      border-radius: 5px;
      padding: 1rem;
      h4 {
        padding: 0.8rem 0 0.4rem;
        font-size: 1.2rem;
        transition: color 350ms ease;
      }
      p {
        font-size: 0.9rem;
        color: var(--black);
      }
      transition: background-color 550ms ease;
      &:hover {
        background-color: #f1eff8;
        h4 {
          color: var(--blue);
        }
      }
    }
  }

  ${media.sm} {
    padding: 3rem 0;
    div.methods {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`
