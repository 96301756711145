import React from 'react'
import SecondaryHeader from '../components/header/secondary/secondary-header'
import ContactPage from '../components/contact/contact'
import FaqSection from '../components/faq/faq-section'
import SEO from '../components/seo/seo'
function Contact() {
  const title = 'Contact Blue Geek Hosting'
  const subtitle =
    'Having Server troubles? Not sure how to get started? Shoot us a message'

  return (
    <>
      <SEO title="Contact US" />
      <SecondaryHeader title={title} subtitle={subtitle} />
      <ContactPage />
      <FaqSection />
    </>
  )
}

export default Contact
