import React from 'react'
import { ContactWrapper, ContactMethodWrapper } from './contact-styles'
import { Container, TwoColumnWrapper } from '../common/common-styles'
import contactSVG from '../../images/features/contact.svg'
import icons from '../../images/icons/icons.svg'

function ContactPage() {
  return (
    <Container>
      <ContactWrapper>
        <TwoColumnWrapper>
          <div className="image">
            <img src={contactSVG} alt="Guy with Computer illustration" />
          </div>
          <div className="content">
            <form action="">
              <h2>Have a Question? </h2>
              <label id="label-name" htmlFor="name">
                Name
                <input type="text" id="name" placeholder="Your Name" />
              </label>
              <label id="label-email" htmlFor="email">
                Email
                <input type="text" id="email" placeholder="Your Email" />
              </label>
              <label id="label-message" htmlFor="message">
                Message
                <textarea type="text" id="message" placeholder="Your Message" />
              </label>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </TwoColumnWrapper>
        <ContactMethodWrapper>
          <h1>Having Trouble?</h1>
          <div className="methods">
            <a href="https://my.bluegeekhosting.com/submitticket.php">
              <div className="method">
                <svg width="61" height="61" viewBox="0 0 61 61" fill="none">
                  <use href={`${icons}#feature-ticket`} />
                </svg>
                <h4>Support Ticket</h4>
                <p>Raise a Support ticket from Client Area</p>
              </div>
            </a>
            <a href="mailto:support@bluegeekhosting.com">
              <div className="method">
                <svg width="61" height="61" viewBox="0 0 61 61" fill="none">
                  <use href={`${icons}#feature-email`} />
                </svg>
                <h4>Email Support</h4>
                <p>
                  Send us your message on <br /> support@bluegeekhosting.com
                </p>
              </div>
            </a>{' '}
            <a href="javascript:void(Tawk_API.toggle())">
              <div className="method">
                <svg width="61" height="61" viewBox="0 0 61 61" fill="none">
                  <use href={`${icons}#feature-support`} />
                </svg>
                <h4>Live Chat</h4>
                <p>
                  Get instant support with live chat with one of our support
                  staff
                </p>
              </div>
            </a>
          </div>
        </ContactMethodWrapper>
      </ContactWrapper>
    </Container>
  )
}

export default ContactPage
